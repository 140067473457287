import { get } from "@rails/request.js";
import AbortController from "abort-controller";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  async connect() {
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;

    this.observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.loadPrice();
        }
      },
      { threshold: 0.1 }
    );

    this.observer.observe(this.element);
  }

  async loadPrice() {
    try {
      this.baseUrl = this.element.dataset.baseUrl;
      this.url = this.element.dataset.url;
      this.id = this.element.dataset.id;
      this.observer.disconnect();

      let response = await get(this.baseUrl + this.url + this.id, {
        signal: this.signal,
      });
      let materials = await response.text;
      this.element.querySelector(".price").innerHTML = materials;
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Failed to load price:", error);
      }
    }
  }

  abort() {
    document.dispatchEvent(new Event("abort"));
  }
}
